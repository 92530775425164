import * as Contract from '@tableau/api-external-contract-js';

export class ConnectedServer implements Contract.ConnectedServer {
  public constructor(protected _userId?: string, protected _siteId?: string, protected _workbookId?: string) {}

  public get userId(): string | undefined {
    return this._userId;
  }

  public get siteId(): string | undefined {
    return this._siteId;
  }

  public get workbookId(): string | undefined {
    return this._workbookId;
  }
}
