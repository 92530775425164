import * as Contract from '@tableau/api-external-contract-js';
import { TableauEventType } from '@tableau/api-external-contract-js';
import { TableauEvent } from './TableauEvent';

export class TableauSheetEvent extends TableauEvent implements Contract.TableauSheetEvent {
  private _sheet: Contract.ExtensionSheet;

  public get sheet(): Contract.ExtensionSheet {
    return this._sheet;
  }

  public constructor(type: TableauEventType, sheet: Contract.ExtensionSheet) {
    super(type);

    this._sheet = sheet;
  }
}
