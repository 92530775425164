import * as Contract from '@tableau/api-external-contract-js';
import { ExtensionEnvironment } from '@tableau/api-internal-contract-js';
import { ApiVersion, InternalToExternalEnumMappings as EnumMappings } from '@tableau/api-shared-js';
import { ConnectedServer } from '../Impl/ConnectedServer';
import { WorkbookFormattingEnvUtil } from './WorkbookFormattingEnvUtil';

/**
 * Implementation of the external environment namespace.
 * Environment does not follow the Impl pattern as it is
 * just a property bag.
 * First class extensions can get to ConnectedServer with: environment['connectedServer']
 */
export class Environment implements Contract.Environment {
  /**The following is an index signature. It allows properties to be accessed by name.
   * This allows us to add properties dynamically (see usage of this.connectedServer below) without Typescript errors.
   * The reason we do this is so that 3rd-party extension developers do not see the connectedServer property
   * that is only available to first class extension developers.
   * The type of the index sigature needs to be a union of all properties in the object, since we could
   * access any property using the property index method.
   */
  [x: string]: ConnectedServer | string | Contract.WorkbookFormatting | undefined;
  private _apiVersion: string;
  private _context: Contract.ExtensionContext;
  private _formatting?: Contract.WorkbookFormatting;
  private _language: string;
  private _country?: string;
  private _locale: string;
  private _mode: Contract.ExtensionMode;
  private _operatingSystem: string;
  private _tableauVersion: string;

  public constructor(extensionEnvironment: ExtensionEnvironment) {
    this._apiVersion = ApiVersion.Instance && ApiVersion.Instance.formattedValue; // maj.min.fix (no build)
    this._context = EnumMappings.extensionContext.convert(extensionEnvironment.extensionContext);
    this._formatting = WorkbookFormattingEnvUtil.convertToWorkbookFormatting(extensionEnvironment.workbookFormatting);
    this._language = extensionEnvironment.extensionLanguage;
    this._country = extensionEnvironment.extensionCountry;
    this._locale = extensionEnvironment.extensionLocale;
    this._mode = EnumMappings.extensionMode.convert(extensionEnvironment.extensionMode);
    this._operatingSystem = extensionEnvironment.operatingSystem;
    this._tableauVersion = extensionEnvironment.tableauVersion;

    // If we have a userId, then we are a first class extension
    // Add our hidden member 'connectedServer'
    if (extensionEnvironment.userId) {
      this.connectedServer = new ConnectedServer(extensionEnvironment.userId, extensionEnvironment.siteId, extensionEnvironment.workbookId);
    }
  }

  public get apiVersion(): string {
    return this._apiVersion;
  }

  public get context(): Contract.ExtensionContext {
    return this._context;
  }

  public get workbookFormatting(): Contract.WorkbookFormatting | undefined {
    return this._formatting;
  }

  public get language(): string {
    return this._language;
  }

  public get country(): string | undefined {
    return this._country;
  }

  public get locale(): string {
    return this._locale;
  }

  public get mode(): Contract.ExtensionMode {
    return this._mode;
  }

  public get operatingSystem(): string {
    return this._operatingSystem;
  }

  public get tableauVersion(): string {
    return this._tableauVersion;
  }
}
