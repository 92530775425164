import { ErrorCodes } from '@tableau/api-external-contract-js';
import { ExecuteParameters, ExtensionSettingsInfo, ParameterId, VerbId } from '@tableau/api-internal-contract-js';
import { ServiceImplBase, TableauError } from '@tableau/api-shared-js';
import { ExtensionsServiceNames } from '../ExtensionsServiceNames';
import { SettingsCollection, SettingsService } from '../SettingsService';

export class SettingsServiceImpl extends ServiceImplBase implements SettingsService {
  public get serviceName(): string {
    return ExtensionsServiceNames.SettingsService;
  }

  public saveSettingsAsync(settings: SettingsCollection): Promise<SettingsCollection> {
    const parameters: ExecuteParameters = {
      [ParameterId.FunctionName]: 'saveSettingsAsync',
      [ParameterId.SettingsValues]: settings,
    };

    return this.execute(VerbId.SaveExtensionSettings, parameters).then<SettingsCollection>((value) => {
      const result = value.result as ExtensionSettingsInfo;

      if (!result || !result.settingsValues) {
        throw new TableauError(ErrorCodes.InternalError, 'Unexpected error savings settings.');
      }

      return result.settingsValues;
    });
  }
}
