import * as Contract from '@tableau/api-external-contract-js';
import { ExtensionSettingsInfo, NotificationId, SettingsEvent } from '@tableau/api-internal-contract-js';
import {
  ApiServiceRegistry,
  ErrorHelpers,
  NotificationService,
  ServiceNames,
  SingleEventManager,
  SingleEventManagerImpl,
  TableauError,
} from '@tableau/api-shared-js';
import { TableauEvent } from '../Events/TableauEvent';
import { ExtensionsServiceNames } from '../Services/ExtensionsServiceNames';
import { ExtensionsRegistryId } from '../Services/ServiceRegistryUtil';
import { SettingsCollection, SettingsService } from '../Services/SettingsService';

class SettingsChangedEvent extends TableauEvent implements Contract.SettingsChangedEvent {
  public constructor(private _newSettings: { [key: string]: string }) {
    super(Contract.TableauEventType.SettingsChanged);
  }

  public get newSettings(): SettingsCollection {
    return this._newSettings;
  }
}

export class SettingsImpl {
  private static ASYNC_SAVE_IN_PROGRESS = 'Async Save is in progress, updating settings is not allowed.';
  private _isModified: boolean;
  private _currentSettings: SettingsCollection;

  // Since promises can't be introspected for state, keep a variable that
  // indicates a save is in progress, so that set/erase can't be called during a save.
  private _saveInProgress = false;

  public constructor(settingsInfo: ExtensionSettingsInfo) {
    this.initializeSettings(settingsInfo);
  }

  public erase(key: string): void {
    ErrorHelpers.verifyParameter(key, 'key');

    // Only make a modification if we have the key already
    if (this._currentSettings[key]) {
      this.verifySettingsAreUnlocked();

      delete this._currentSettings[key];
      this._isModified = true;
    }
  }

  public get(key: string): string | undefined {
    ErrorHelpers.verifyParameter(key, 'key');

    return this._currentSettings[key];
  }

  public getAll(): SettingsCollection {
    // Returns a mutable copy of the settings
    return Object.assign({}, this._currentSettings);
  }

  public get isModified(): boolean {
    return this._isModified;
  }

  public saveAsync(): Promise<SettingsCollection> {
    this.verifySettingsAreUnlocked();

    // Just resolve immediately if settings are unchanged
    if (!this._isModified) {
      return Promise.resolve<SettingsCollection>(this._currentSettings);
    }

    this._saveInProgress = true;

    // Use the settings service to save settings to twb
    const settingsService = ApiServiceRegistry.get(ExtensionsRegistryId).getService<SettingsService>(
      ExtensionsServiceNames.SettingsService,
    );

    return settingsService.saveSettingsAsync(this._currentSettings).then<SettingsCollection>(
      (newSettings) => {
        this._saveInProgress = false;
        this._isModified = false;
        if (this._currentSettings === undefined) {
          this._currentSettings = newSettings;
        } else {
          Object.assign(this._currentSettings, newSettings);
        }
        return newSettings;
      },
      (reason) => {
        this._saveInProgress = false;
        return Promise.reject(reason);
      },
    );
  }

  public set(key: string, value: string): void {
    ErrorHelpers.verifyStringParameter(key, 'key'); // Key shouldn't be an empty string.
    ErrorHelpers.verifyParameter(value, 'value'); // Empty string value is allowed.
    this.verifySettingsAreUnlocked();

    this._currentSettings[key] = value;
    this._isModified = true;
  }

  /**
   * Initializes all events relevant to settings object.  This is only a settingsUpdate event currently.
   *
   * @returns {Array<SingleEventManager>} Collection of event managers to pass to an EventListenerManager.
   */
  public initializeEvents(): Array<SingleEventManager> {
    const results = new Array<SingleEventManager>();
    let notificationService: NotificationService;

    try {
      notificationService = ApiServiceRegistry.get(ExtensionsRegistryId).getService<NotificationService>(ServiceNames.Notification);
    } catch (e) {
      // If we don't have this service registered, just return
      return results;
    }

    const settingsChangedEvent = new SingleEventManagerImpl<SettingsChangedEvent>(Contract.TableauEventType.SettingsChanged);
    notificationService.registerHandler(
      NotificationId.SettingsChanged,
      () => true,
      (event: SettingsEvent) => {
        this._currentSettings = event.newSettings;
        settingsChangedEvent.triggerEvent(() => new SettingsChangedEvent(event.newSettings));
      },
    );

    results.push(settingsChangedEvent);

    return results;
  }

  private initializeSettings(settingsInfo: ExtensionSettingsInfo): void {
    ErrorHelpers.verifyParameter(settingsInfo, 'settingsInfo');
    ErrorHelpers.verifyParameter(settingsInfo.settingsValues, 'settingsInfo.SettingsValues');

    this._currentSettings = settingsInfo.settingsValues;

    // Reset the isModified flag
    this._isModified = false;
  }

  /**
   * This helper should be called before any local update to this.currentSettings.
   * Checks if a current save call is still in progress and throws an error if so.
   */
  private verifySettingsAreUnlocked(): void {
    if (this._saveInProgress) {
      throw new TableauError(Contract.ErrorCodes.SettingSaveInProgress, SettingsImpl.ASYNC_SAVE_IN_PROGRESS);
    }
  }
}
