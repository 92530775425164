import { InternalApiDispatcher } from '@tableau/api-internal-contract-js';
import { ApiServiceRegistry } from '@tableau/api-shared-js';
import { InitializationServiceImpl } from './Impl/InitializationServiceImpl';
import { SettingsServiceImpl } from './Impl/SettingsServiceImpl';
import { UIServiceImpl } from './Impl/UIServiceImpl';
import { ExtensionsRegistryId } from './ServiceRegistryUtil';

export function registerAllExtensionsServices(dispatcher: InternalApiDispatcher): void {
  ApiServiceRegistry.get(ExtensionsRegistryId).registerService(new SettingsServiceImpl(dispatcher, ExtensionsRegistryId));
  ApiServiceRegistry.get(ExtensionsRegistryId).registerService(new UIServiceImpl(dispatcher, ExtensionsRegistryId));
}

export function registerInitializationExtensionsServices(dispatcher: InternalApiDispatcher): void {
  ApiServiceRegistry.get(ExtensionsRegistryId).registerService(new InitializationServiceImpl(dispatcher, ExtensionsRegistryId));
}
