import * as Contract from '@tableau/api-external-contract-js';
import { NotificationId } from '@tableau/api-internal-contract-js';
import {
  ApiServiceRegistry,
  ErrorHelpers,
  EventListenerManager,
  NotificationService,
  ParameterImpl,
  ServiceNames,
  SingleEventManager,
  SingleEventManagerImpl,
} from '@tableau/api-shared-js';
import { ParameterChangedEvent } from '../Events/ParameterChangedEvent';
import { ExtensionsRegistryId } from '../Services/ServiceRegistryUtil';

/**
 * Implementation of the Parameter contract. Calls down to the impl
 * class for almost all of the work it does.
 */
export class Parameter extends EventListenerManager implements Contract.Parameter {
  public constructor(private parameterImpl: ParameterImpl, sheet: Contract.ExtensionSheet) {
    super();

    // Initialize our event handling for this class
    this.initializeEvents(sheet).forEach((e) => this.addNewEventType(e));
  }

  public get name(): string {
    return this.parameterImpl.name;
  }

  public get currentValue(): Contract.DataValue {
    return this.parameterImpl.currentValue;
  }

  public get dataType(): Contract.DataType {
    return this.parameterImpl.dataType;
  }

  public get allowableValues(): Contract.ParameterDomainRestriction {
    return this.parameterImpl.allowableValues;
  }

  public get id(): string {
    return this.parameterImpl.id;
  }

  public changeValueAsync(newValue: string | number | boolean | Date): Promise<Contract.DataValue> {
    return this.parameterImpl.changeValueAsync(newValue);
  }

  /**
   * Helper method which goes through and registers each event type this impl knows about
   * with the NotificationService. It returns an array of SingleEventManager objects which
   * can then be passed to an EventListenerManager to handle user registration / unregistration.
   *
   * @param sheet The sheet object which will be included with the event notifications
   * @returns {Array<SingleEventManager>} Collection of event managers to pass to an EventListenerManager
   */
  public initializeEvents(sheet: Contract.ExtensionSheet): Array<SingleEventManager> {
    ErrorHelpers.verifyInternalValue(sheet, 'sheet');

    const results = new Array<SingleEventManager>();
    let notificationService: NotificationService;

    try {
      notificationService = ApiServiceRegistry.get(ExtensionsRegistryId).getService<NotificationService>(ServiceNames.Notification);
    } catch (e) {
      // If we don't have this service registered, just return
      return results;
    }

    // Initialize all of the event managers we'll need (one for each event type)
    const parameterEvent = new SingleEventManagerImpl<ParameterChangedEvent>(Contract.TableauEventType.ParameterChanged);
    notificationService.registerHandler(
      NotificationId.ParameterChanged,
      (model) => {
        const fieldName = model as string;
        return fieldName === this.parameterImpl.id;
      },
      (fieldName: string) => {
        parameterEvent.triggerEvent(() => new ParameterChangedEvent(fieldName, sheet));
      },
    );

    results.push(parameterEvent);

    return results;
  }
}
