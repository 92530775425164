import * as Contract from '@tableau/api-external-contract-js';
import { InternalToExternalEnumMappings as EnumMappings } from '@tableau/api-shared-js';
import { WorkbookFormatting } from '../../../../api-internal-contract-js/lib/src/JsApiInternalContract';

export class WorkbookFormattingEnvUtil {
  public static convertToWorkbookFormatting(workbookFormattingModel?: WorkbookFormatting): Contract.WorkbookFormatting | undefined {
    if (!workbookFormattingModel) {
      return undefined;
    } else {
      const formattingMap: Array<Contract.FormattingSheet> = workbookFormattingModel.formattingSheets.map((currentFormattingSheet) => {
        const formattingSheet: Contract.FormattingSheet = {
          classNameKey: EnumMappings.classNameKey.convert(currentFormattingSheet.classNameKey),
          cssProperties: {
            fontFamily: currentFormattingSheet.cssProperties.fontFamily,
            fontSize: currentFormattingSheet.cssProperties.fontSize,
            fontWeight: currentFormattingSheet.cssProperties.fontWeight,
            fontStyle: currentFormattingSheet.cssProperties.fontStyle,
            textDecoration: currentFormattingSheet.cssProperties.textDecoration,
            color: currentFormattingSheet.cssProperties.color,
          },
        };
        return formattingSheet;
      });
      const result: Contract.WorkbookFormatting = {
        formattingSheets: formattingMap,
      };
      return result;
    }
  }
}
