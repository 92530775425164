import { Model } from '@tableau/api-internal-contract-js';

export const BentonSansFontFamilyName = 'Benton Sans';
export const BentonSansLowDPIFontFamilyName = 'Benton Sans Low-DPI';

export const BentonSansBookFontFaceName = 'Benton Sans Book';
export const BentonSansBookItalicFontFaceName = 'Benton Sans Book Italic';
export const BentonSansSemiDemiFontFaceName = 'Benton Sans SemiDemi';
export const BentonSansSemiDemiItalicFontFaceName = 'Benton Sans SemiDemi Italic';
export const BentonSansLightFontFaceName = 'Benton Sans Light';
export const BentonSansMediumFontFaceName = 'Benton Sans Medium';

export const SupportedBentonSansFontFaces: Model[] = [
  BentonSansBookFontFaceName,
  BentonSansBookItalicFontFaceName,
  BentonSansSemiDemiFontFaceName,
  BentonSansSemiDemiItalicFontFaceName,
  BentonSansLightFontFaceName,
  BentonSansMediumFontFaceName,
];
