import { ExecuteParameters, ExtensionBootstrapInfo, ParameterId, VerbId } from '@tableau/api-internal-contract-js';
import { ServiceImplBase } from '@tableau/api-shared-js';
import { ExtensionsServiceNames } from '../ExtensionsServiceNames';
import { InitializationService } from '../InitializationService';

export class InitializationServiceImpl extends ServiceImplBase implements InitializationService {
  public get serviceName(): string {
    return ExtensionsServiceNames.InitializationService;
  }

  public initializeDashboardExtensionsAsync(isExtensionDialog: boolean, contextMenuIds: string[]): Promise<ExtensionBootstrapInfo> {
    const params: ExecuteParameters = {
      [ParameterId.FunctionName]: 'initializeDashboardExtensionsAsync',
      [ParameterId.ExtensionContextMenuIds]: contextMenuIds,
      [ParameterId.IsExtensionDialog]: isExtensionDialog,
    };

    return this.execute(VerbId.InitializeExtension, params).then<ExtensionBootstrapInfo>((response) => {
      // TODO - Validate return value

      const result = response.result as ExtensionBootstrapInfo;
      return result;
    });
  }
}
