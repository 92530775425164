import { WebComponentLocalSettings, WebComponentSettingsShared } from './WebComponentInterfaces';

/**
 * The names of the string constants you can use as HTML attributes for the <tableau-ask-data> web component.
 * @ignore
 */
export enum AskDataAttributes {
  Origin = 'origin',
  ShowEmbed = 'showEmbed',
  ShowPin = 'showPin',
  ShowSave = 'showSave',
  ShowShare = 'showShare',
}

export interface AskDataSettings extends WebComponentSettingsShared, WebComponentLocalSettings {
  /**
   * Indicates whether the embed button is hidden or visible.
   * ```
   * <tableau-ask-data id="tableauAskData" showEmbed>
   * ```
   */
  showEmbed?: boolean;

  /**
   * Indicates whether the pin button is hidden or visible.
   * ```
   * <tableau-ask-data id="tableauAskData" showPin>
   * ```
   */
  showPin?: boolean;

  /**
   * Indicates whether the save button is hidden or visible.
   * ```
   * <tableau-ask-data id="tableauAskData" showSave>
   * ```
   */
  showSave?: boolean;

  /**
   * Indicates whether the share button is hidden or visible.
   * ```
   * <tableau-ask-data id="tableauAskData" showShare>
   * ```
   */
  showShare?: boolean;

  /**
   *  Used for telemetry
   * ```
   * <tableau-ask-data id="tableauAskData" origin="embeddingSite">
   * ```
   */
  origin?: string;
}

/**
 * The interface for the top level AskData object.
 **/
export interface AskData extends AskDataSettings {}
