import * as Contract from '@tableau/api-external-contract-js';
import { TableauEvent } from './TableauEvent';

export class DashboardLayoutChangedEvent extends TableauEvent implements Contract.DashboardLayoutChangedEvent {
  public constructor(public _dashboardLayoutChangeDetails: Contract.DashboardLayoutChangeDetails) {
    super(Contract.TableauEventType.DashboardLayoutChanged);
  }

  public get dashboardLayoutChangeDetails(): Contract.DashboardLayoutChangeDetails {
    return this._dashboardLayoutChangeDetails;
  }
}
