import * as Contract from '@tableau/api-external-contract-js';
import { DataSchema, DataSource as DataSourceInfo } from '@tableau/api-internal-contract-js';
import { ApiServiceRegistry, DataSource, DataSourceImpl, DataSourceService, ServiceNames, WorkbookImpl } from '@tableau/api-shared-js';
import { ExtensionsRegistryId } from '../Services/ServiceRegistryUtil';

export class ExtensionWorkbookImpl extends WorkbookImpl {
  public getAllDataSourcesAsync(): Promise<Array<Contract.DataSource>> {
    const service = ApiServiceRegistry.get(ExtensionsRegistryId).getService<DataSourceService>(ServiceNames.DataSourceService);

    return service.getAllDataSourcesAsync().then<Array<Contract.DataSource>>((result) => {
      const dataSchema: DataSchema = result;
      const dataSources: Array<Contract.DataSource> = [];

      // Loop through all data sources and add them.
      Object.values(dataSchema.dataSources).forEach((ds) => {
        dataSources.push(this.createDataSourceFromInfo(ds));
      });

      return dataSources;
    });
  }

  private createDataSourceFromInfo(dataSourceInfo: DataSourceInfo): DataSource {
    const dataSourceImpl = new DataSourceImpl(dataSourceInfo, ExtensionsRegistryId);
    const dataSource = new DataSource(dataSourceImpl);
    dataSourceImpl.initializeWithPublicInterfaces(dataSource);
    return dataSource;
  }
}
