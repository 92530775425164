import { Model } from '@tableau/api-internal-contract-js';

export const TableauBookFontFamilyName = 'Tableau Book';
export const TableauLightFontFamilyName = 'Tableau Light';
export const TableauRegularFontFamilyName = 'Tableau Regular';
export const TableauMediumFontFamilyName = 'Tableau Medium';
export const TableauSemiboldFontFamilyName = 'Tableau Semibold';
export const TableauBoldFontFamilyName = 'Tableau Bold';

export const TableauBookFontFaceName = 'Tableau Book';
export const TableauBoldFontFaceName = 'Tableau Bold';
export const TableauLightFontFaceName = 'Tableau Light';
export const TableauMediumFontFaceName = 'Tableau Medium';
export const TableauRegularFontFaceName = 'Tableau Regular';
export const TableauSemiBoldFontFaceName = 'Tableau Semi Bold';

export const SupportedTableauFontFaces: Model[] = [
  TableauBookFontFaceName,
  TableauBoldFontFaceName,
  TableauLightFontFaceName,
  TableauMediumFontFaceName,
  TableauRegularFontFaceName,
  TableauSemiBoldFontFaceName,
];
