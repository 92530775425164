import * as Contract from '@tableau/api-external-contract-js';
import { CallbackMap, ExtensionsImpl } from '../Impl/ExtensionsImpl';

/**
 * Implementation of the external Extensions namespace.
 */
export class Extensions implements Contract.Extensions {
  public constructor(private extensionImpl: ExtensionsImpl) {
    this.extensionImpl = extensionImpl;
  }

  public get dashboardContent(): Contract.DashboardContent {
    return this.extensionImpl.dashboardContent;
  }

  public get environment(): Contract.Environment {
    return this.extensionImpl.environment;
  }

  public get settings(): Contract.Settings {
    return this.extensionImpl.settings;
  }

  public get ui(): Contract.UI {
    return this.extensionImpl.ui;
  }

  public get workbook(): Contract.ExtensionWorkbook {
    return this.extensionImpl.workbook;
  }

  public get dashboardObjectId(): number {
    return this.extensionImpl.extensionZoneId;
  }

  public initializeAsync(contextMenuCallbacks?: CallbackMap): Promise<void> {
    return this.extensionImpl.initializeAsync(false, contextMenuCallbacks).then<void>();
  }

  public initializeDialogAsync(): Promise<string> {
    return this.extensionImpl.initializeAsync(true);
  }

  public createVizImageAsync(inputSpec: object): Promise<string> {
    return this.extensionImpl.createVizImageAsync(inputSpec);
  }

  public setClickThroughAsync(clickThroughEnabled: boolean): Promise<void> {
    return this.extensionImpl.setClickThroughAsync(clickThroughEnabled);
  }
}
