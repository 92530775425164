import * as Contract from '@tableau/api-external-contract-js';
import { ErrorCodes } from '@tableau/api-external-contract-js';
import { DialogUpdateEvent, NotificationId } from '@tableau/api-internal-contract-js';
import { ApiServiceRegistry, NotificationService, ServiceNames, TableauError } from '@tableau/api-shared-js';
import { ExtensionsServiceNames } from '../Services/ExtensionsServiceNames';
import { ExtensionsRegistryId } from '../Services/ServiceRegistryUtil';
import { UIService } from '../Services/UIService';

export class UIImpl {
  public displayDialogAsync(url: string, payload?: string, options?: Contract.DialogOptions): Promise<string> {
    const uiService = ApiServiceRegistry.get(ExtensionsRegistryId).getService<UIService>(ExtensionsServiceNames.UIService);
    const notificationService: NotificationService = ApiServiceRegistry.get(ExtensionsRegistryId).getService<NotificationService>(
      ServiceNames.Notification,
    );

    return new Promise((resolve, reject) => {
      uiService
        .displayDialogAsync(url, payload || '', options)
        .then(() => {
          const unregisterFn = notificationService.registerHandler(
            NotificationId.ExtensionDialogUpdate,
            () => {
              // Let through any dialog update event
              return true;
            },
            (event: DialogUpdateEvent) => {
              if (event.isCloseEvent) {
                if (event.closePayload === undefined) {
                  throw new TableauError(Contract.ErrorCodes.InternalError, 'closePayload is undefined');
                }
                resolve(event.closePayload);
              } else {
                reject(new TableauError(ErrorCodes.DialogClosedByUser, 'Extension dialog closed by user.'));
              }

              unregisterFn();
            },
          );
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  public closeDialog(payload?: string): void {
    const uiService = ApiServiceRegistry.get(ExtensionsRegistryId).getService<UIService>(ExtensionsServiceNames.UIService);

    uiService.closeDialog(payload);
  }
}
